input.form-control {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #DDDDDD;

  &:focus {
    box-shadow: 0 0 0 2px var(--ui-orangeShadow);
    border: 1px solid var(--ui-orange);
  }


}

.InlineButtonInput {
  position: relative;
  display: inline-block;
  width: 100%;

  button {
    height: 24px;
    width: 24px;
    position: absolute;
    top: calc(50% - 12px);
    right: 8px;
    padding: 0px;
  }
}

.FormContainer {
  display: flex;



  &.InputGroup {
    width: 100%;

    input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      &:focus {
        z-index: 1;
      }
    }

    .form-floating {
      flex: 1 0 auto;

      &~button {
        height: inherit !important;
        border-radius: 200px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }

    button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      height: inherit;
    }
  }


}